@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
}

body {
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
}

.container {
  @apply px-20 md:px-40 lg:px-80;
}

.modal-open {
  overflow: hidden;
}

.logo {
  display: flex;
}
/* .logo:before {
  content: '';
  display: inline-block;
  margin: -8px 8px 0 0;
  width: 30px;
  height: 30px;
  background: url('./assets/extra-logo.png') no-repeat 50% 50%;
  background-size: contain;
} */

.text-stroke {
  @apply text-white;
  text-shadow: -1px -1px 0 palevioletred, 1px -1px 0 palevioletred,
    -1px 1px 0 palevioletred, 1px 1px 0 palevioletred;
  font-size: 30px;
  line-height: 36px;
  transform: rotate(4deg);
  transform-origin: 50% 50%;
  text-align: center;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;*/
}

.bg-circle {
  background: url("./assets/circle.svg") no-repeat 50% 50%;
}

.modal-content {
  @apply fixed bg-white top-50 bottom-74 md:bottom-0 left-0 right-0 overflow-y-auto;
}

.nav-main .active {
  @apply font-bold;
}

.nav-footer a {
  @apply block w-full;
}

.nav-footer a:after {
  @apply block w-full h-5 mt-5 rounded-full;
  content: "";
}
.nav-footer .active:after {
  @apply bg-blue-400;
}

.card-description p + p {
  @apply mt-8;
}
.card-description span + p {
  display: inline;
}

.card-description a {
  @apply font-semibold text-blue-400 underline;

  &:hover {
    @apply no-underline;
  }
}

.icon svg {
  @apply w-full h-auto;
}
